import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import HeroSection from './components/HeroSection';
import WatchList from './WatchList';
import Navbar from './components/Navbar';
import Footer from './components/Footer';
import AboutSection from './components/AboutSection';
import FloatingIcons from './components/FloatingIcons';
import { Link } from 'react-router-dom';
import '../../assets/fonts/fonts.css';

const Home = () => {
  const { t } = useTranslation();
  const [isVisible, setIsVisible] = useState(false);

  const watchListRef = useRef(null);
  const aboutSectionRef = useRef(null);
  const footerRef = useRef(null);

  useEffect(() => {

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          entry.target.style.opacity = '1';
          entry.target.style.transform = 'translateY(0)';
        }
      });
    }, { threshold: 0.1 });

    if (watchListRef.current) observer.observe(watchListRef.current);
    if (aboutSectionRef.current) observer.observe(aboutSectionRef.current);
    if (footerRef.current) observer.observe(footerRef.current);


    const toggleVisibility = () => {
      if (window.scrollY > 300) {
        setIsVisible(true);
      } else {
        setIsVisible(false);
      }
    };

    window.addEventListener('scroll', toggleVisibility);

    return () => {
      if (watchListRef.current) observer.unobserve(watchListRef.current);
      if (aboutSectionRef.current) observer.unobserve(aboutSectionRef.current);
      if (footerRef.current) observer.unobserve(footerRef.current);
      window.removeEventListener('scroll', toggleVisibility);
    };
  }, []);


  const animationStyle = {
    opacity: 0,
    transform: 'translateY(50px)',
    transition: 'opacity 0.8s ease-out, transform 0.8s ease-out',
  };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    });
  };

  return (
    <div>
      <Navbar />
      <HeroSection />

      <div style={styles.collectionSection}>
        <h2 style={styles.collectionTitle}>{t('home.our_collection')}</h2>
        <div ref={watchListRef} style={animationStyle}>
          <WatchList />
        </div>
        <div style={styles.buttonContainer}>
          <Link to="/watches" className="btn" style={styles.viewAllButton}>
            {t('home.view_all_watches')}
          </Link>
        </div>
      </div>

      <div ref={aboutSectionRef} style={animationStyle}>
        <AboutSection />
      </div>

      <div ref={footerRef} style={animationStyle}>
        <Footer />
      </div>

      <FloatingIcons />

      {isVisible && (
        <button onClick={scrollToTop} style={styles.backToTopButton}>
          ↑
        </button>
      )}
    </div>
  );
};

const styles = {
  collectionSection: {
    padding: '40px 0px',
  },
  collectionTitle: {
    backgroundColor: '#10381f',
    color: '#ffffff',
    padding: '15px 30px',
    fontSize: '1.5rem',
    textAlign: 'right',
    marginBottom: '20px',
    marginTop: '0',
    width: '300px',
    fontFamily: 'Red Rose, sans-serif',
  },
  buttonContainer: {
    textAlign: 'center',
    marginTop: '20px',
  },
  viewAllButton: {
    padding: '10px 30px',
    fontSize: '1.2rem',
    textTransform: 'uppercase',
    backgroundColor: '#10381f',
    color: '#ffffff',
    fontFamily: 'Red Rose, sans-serif',
    transition: 'all 0.3s ease',
    cursor: 'pointer',
  },
  backToTopButton: {
    position: 'fixed',
    bottom: '20px',
    left: '20px',
    padding: '10px 20px',
    backgroundColor: 'black',
    color: '#fff',
    border: 'none',
    borderRadius: '5px',
    fontSize: '1rem',
    cursor: 'pointer',
    fontFamily: 'Red Rose, sans-serif',
    transition: 'opacity 0.3s',
    zIndex: 1000,
  },
};

export default Home;
