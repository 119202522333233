import React, { useEffect, useState } from 'react';
import WatchController from '../../controller/WatchController';
import AddWatch from './components/AddWatch';
import UpdateWatch from './components/UpdateWatch';
import { Card, CardContent, CardMedia, Typography, IconButton, Box, Button, CircularProgress, Pagination } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const ManageWatches = () => {
  const [watches, setWatches] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [editingWatch, setEditingWatch] = useState(null);
  const [currentPage, setCurrentPage] = useState(1);
  const watchesPerPage = 20;

  const fetchWatches = async () => {
    setLoading(true);
    try {
      const data = await WatchController.fetchWatches();
      const watchesWithImages = data.map((watch) => ({
        ...watch,
        imageUrls: watch.imageUrls || [], 
      }));
      setWatches(watchesWithImages); 
    } catch (error) {
      console.error('Failed to fetch watches:', error);
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWatches(); 
  }, []);

  const handleDelete = async (id) => {
    const confirmDelete = window.confirm('Are you sure you want to delete this watch?');
    if (confirmDelete) {
      try {
        const watchToDelete = watches.find((watch) => watch.id === id);
        if (!watchToDelete || !watchToDelete.imageUrls) {
          throw new Error('Watch or image URLs not found');
        }

        await WatchController.removeWatch(id, watchToDelete.imageUrls);

        setWatches((prevWatches) => prevWatches.filter((watch) => watch.id !== id)); 
      } catch (error) {
        console.error('Failed to delete watch:', error);
      }
    }
  };

  const handleAddWatch = async (newWatch) => {
    try {
      if (!newWatch.imageUrls || newWatch.imageUrls.length === 0) {
        throw new Error("No images found for this watch");
      }

      setWatches((prevWatches) => [...prevWatches, newWatch]); 
      setShowAddForm(false); 
    } catch (error) {
      console.error('Failed to add watch:', error);
    }
  };

  const handleEdit = (watch) => {
    setEditingWatch(watch);
    setShowUpdateForm(true);
  };

  const handleUpdateWatch = async (updatedWatch) => {
    try {
      if (!updatedWatch.imageUrls || updatedWatch.imageUrls.length === 0) {
        throw new Error("No images found for this watch");
      }

      setWatches((prevWatches) =>
        prevWatches.map((watch) => (watch.id === updatedWatch.id ? updatedWatch : watch))
      ); 

      setShowUpdateForm(false); 
    } catch (error) {
      console.error('Failed to update watch:', error);
    }
  };

  const indexOfLastWatch = currentPage * watchesPerPage;
  const indexOfFirstWatch = indexOfLastWatch - watchesPerPage;
  const currentWatches = watches.slice(indexOfFirstWatch, indexOfLastWatch);

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Box sx={styles.container}>
      <Typography variant="h4" sx={styles.title}>Manage Watches</Typography>

      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setEditingWatch(null);
          setShowAddForm(true);
        }}
        sx={{ marginBottom: '20px' }}
      >
        + Add New Watch
      </Button>

      {showAddForm && (
        <AddWatch
          onAddWatch={handleAddWatch} 
          onClose={() => setShowAddForm(false)}
        />
      )}

      {showUpdateForm && (
        <UpdateWatch
          onUpdateWatch={handleUpdateWatch}
          onClose={() => setShowUpdateForm(false)}
          initialData={editingWatch}
        />
      )}

      <Box sx={styles.cardContainer}>
        {currentWatches.map((watch) => (
          <Box key={watch.id} sx={styles.cardBox}>
            <Card sx={{ height: '100%', display: 'flex', flexDirection: 'column' }}>
              <Box sx={{ display: 'flex', flexDirection: 'row', overflowX: 'auto', padding: 1 }}>
                {watch.imageUrls && watch.imageUrls.length > 0 ? (
                  <Box sx={{ display: 'flex' }}>
                    {watch.imageUrls.map((url, index) => (
                      <Box key={index} sx={{ padding: 1 }}>
                        <CardMedia
                          component="img"
                          image={url}
                          alt={`Image ${index + 1} of ${watch.name}`}
                          sx={{ width: '100%', height: 'auto', borderRadius: '4px' }}
                        />
                      </Box>
                    ))}
                  </Box>
                ) : (
                  <Typography variant="body2" color="textSecondary">
                    No images available
                  </Typography>
                )}
              </Box>

              <CardContent sx={{ flexGrow: 1 }}>
                <Typography variant="h6" component="div">
                  {watch.name}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Brand: {watch.brand}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Reference Number: {watch.refNumber}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Year: {watch.year}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  State: {watch.state}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Case Size: {watch.caseSize}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  Price: {watch.price}
                </Typography>
                <Typography variant="body2" color="textSecondary">
                  In Stock: {watch.inStock ? 'Yes' : 'No'}
                </Typography>
              </CardContent>
              <Box sx={{ padding: 1 }}>
                <IconButton color="primary" onClick={() => handleEdit(watch)}>
                  <EditIcon />
                </IconButton>
                <IconButton color="secondary" onClick={() => handleDelete(watch.id)}>
                  <DeleteIcon />
                </IconButton>
              </Box>
            </Card>
          </Box>
        ))}
      </Box>

      <Pagination
        count={Math.ceil(watches.length / watchesPerPage)}
        page={currentPage}
        onChange={handlePageChange}
        color="primary"
        sx={{ display: 'flex', justifyContent: 'center', marginTop: '20px' }}
      />
    </Box>
  );
};

const styles = {
  container: {
    padding: '20px',
    maxWidth: '1200px',
    margin: '0 auto',
  },
  title: {
    marginBottom: '20px',
    textAlign: 'center',
    color: '#333',
  },
  cardContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    gap: '20px',
  },
  cardBox: {
    flex: '1 1 calc(25% - 20px)',
    maxWidth: 'calc(25% - 20px)',
    minWidth: '200px',
    display: 'flex',
  },
};

export default ManageWatches;
