import React from 'react';
import { useTranslation } from 'react-i18next';
import logo from '../../img/logo.png';

const AboutSection = () => {
  const { t } = useTranslation(); 

  return (
    <div id="about" style={styles.sectionContainer}>
      <div style={styles.textContainer}>
        <div style={styles.logoContainer}>
          <img src={logo} alt="Logo" style={styles.logo} />
        </div>
        <h2 style={styles.title}>{t('about_section.title')}</h2>
        <p style={styles.description}>{t('about_section.description_1')}</p>
        <p style={styles.description}>{t('about_section.description_2')}</p>

        <div style={styles.infoSection}>
          <h3 style={styles.infoTitle}>Zagreb</h3>
          <div style={styles.infoItem}>
            <p style={styles.infoText}><strong>{t('about_section.store_hours')}:</strong></p>
            <p style={styles.infoText}>{t('about_section.monday')}</p>
            <p style={styles.infoText}>{t('about_section.tue_fri')}</p>
            <p style={styles.infoText}>{t('about_section.saturday')}</p>
            <p style={styles.infoText}>{t('about_section.sunday')}</p>
          </div>
          <div style={styles.infoItem}>
            <p style={styles.infoText}><strong>{t('about_section.location')}:</strong></p>
            <p style={styles.infoText}>Preradovićeva 34, 10000 Zagreb</p>
            <p style={styles.infoText}>{t('about_section.phone')}: +385 99 2810 099</p>
          </div>
        </div>
      </div>
    </div>
  );
};

const styles = {
  sectionContainer: {
    width: '100%',
    padding: '50px 20px',
    backgroundColor: '#10381f', 
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
  },
  textContainer: {
    maxWidth: '800px',
    color: '#fff', 
    marginBottom: '50px',
  },
  logoContainer: {
    marginBottom: '20px',
  },
  logo: {
    height: '100px',
    objectFit: 'contain',
  },
  title: {
    fontSize: '2.5rem',
    fontWeight: 'bold',
    letterSpacing: '2px',
    marginBottom: '30px',
    textTransform: 'uppercase',
    fontFamily: 'Red Rose, sans-serif',
  },
  description: {
    fontSize: '1.1rem',
    lineHeight: '1.8',
    marginBottom: '20px',
    fontFamily: 'Corbel, sans-serif',
  },
  infoSection: {
    marginTop: '40px',
    padding: '30px 20px',
    backgroundColor: '#dce0df', 
    borderRadius: '10px',
    color: '#10381f',
    boxShadow: '0 4px 12px rgba(0, 0, 0, 0.3)',
    fontFamily: 'Corbel, sans-serif',
  },
  infoTitle: {
    fontSize: '2rem',
    marginBottom: '20px',
  },
  infoItem: {
    marginBottom: '15px',
  },
  infoText: {
    fontSize: '1.1rem',
  },
};

export default AboutSection;
